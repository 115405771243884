import logoSuperkoders from './logo-superkoders.svg';
import './App.css';
import React, { useEffect, useRef, useState } from 'react';
import { LandingPage } from './components/LandingPage';
import { FinalPage } from './components/FinalPage';
import { QuestionPage } from './components/QuestionPage';
import Confetti from 'react-confetti';

function App() {
	const [page, setPage] = useState(0);
	const handleNextPage = () => {
		setPage(page + 1);
	};
	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [page]);
	const divEl = useRef<HTMLDivElement>(null);

	const width = divEl.current?.offsetWidth;
	const height = divEl.current?.offsetHeight;
	const confettiColors = ['#3cdbc0', '#3cdbc0', '#9B26B6', '#FFD100'];

	return (
		<div className="App">
			{page === 2 ? <Confetti width={width} height={height} colors={confettiColors} /> : null}
			<div className="grid grid-rows-layout min-h-screen relative z-10" ref={divEl}>
				<header role="banner" className="container mx-auto py-6 lg:py-9 pb-16 xxl_pb-24 text-center">
					<div className="row-main">
						<h1>
							<img src={logoSuperkoders} className="mx-auto" alt="SUPERKODERS" width="300" height="19" />
						</h1>
					</div>
				</header>
				<main role="main" id="main" className="container mx-auto flex pb-16 xxl_pb-24">
					<div className="content mx-auto max-w-4xl flex-1 flex">
						{page === 0 ? (
							<LandingPage onClick={handleNextPage} />
						) : page === 1 ? (
							<QuestionPage onAnswerAll={handleNextPage} />
						) : (
							<FinalPage />
						)}
					</div>
				</main>
				<footer role="contentinfo" className="container mx-auto py-6 lg:py-9 text-center pt-4 xxl_pt-24">
					<p className="center">&copy; 2020 SUPERKODERS</p>
				</footer>
			</div>
		</div>
	);
}

export default App;
