import { FC } from 'react';

type Props = {
	disabledsSecs: number;
};

export const Overlay: FC<Props> = ({ disabledsSecs }) => {
	return (
		<div className="modal pointer-events-none fixed w-full h-full top-0 left-0 flex bg-black-light items-center justify-center">
			<div className="modal-overlay absolute w-full h-full md_top-20 flex items-center justify-center">
				<div className="modal-container w-11/12 md:max-w-md mx-auto z-2 text-center">
					<hgroup className="mb-12 mt-24">
						<h1 className={`wrong-answer time-${disabledsSecs} h1 mb-4 text-white`}>Je nám líto,</h1>
						<h2 className="h2 text-white">ale tohle nemůžeme uznat</h2>
					</hgroup>
					<p className="text-white uppercase">
						Tvoje odpověď sice nebyla správně, ale zasloužíš si další šanci.
					</p>
					<p className="mb-12 text-white  uppercase">Neklikej na refresh, nebo začneš úplně na začátku ;-)</p>
					<div className="w-24 md_w-52 h-24 md_h-52 mx-auto m-16">
						<div className="count w-24 md_w-52 h-24 md_h-52">
							<span className="h1 h1--w text-white">
								{disabledsSecs > 1 ? disabledsSecs : <img src="/tom.png" alt="1" />}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
