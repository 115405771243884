import { FC } from 'react';

type Props = {
	onClick?: () => void;
};

export const LandingPage: FC<Props> = ({ onClick }) => (
	<>
		<div className="self-center">
			<hgroup className="mb-12 text-center">
				<h1 className="h1 mb-4">Vánoční Soutěž</h1>
				<h2 className="h2">Info a pravidla</h2>
			</hgroup>
			<p className="mb-4">
				Prokaž svou znalost frontendu! Dva nejrychlejší dostanou možnost si to spolu rozdat o cenu ve finále na
				live streamu! Cílem je dostat se co nejdříve k odkazu, který ti otevře cestu do vysílání.
			</p>
			<p className="mobile-warning mb-4 py-4">
				Jsi na telefonu nebo tabletu? Raději <strong>hned nažhav počítač</strong>. Neříkáme to jen tak,
				poděkovat nám můžeš potom, teď neztrácej čas!
			</p>
			<ul className="mb-12">
				<li className="mb-2">Čeká tě série (záludných) otázek.</li>
				<li className="mb-2">Při správné odpovědi se okamžitě objeví následující otázka.</li>
				<li className="mb-2">
					Odpovíš-li špatně, zůstaneš ve hře, ale na další možnost budeš muset chvilku počkat.
				</li>
				<li className="mb-2">Každá chyba tě ale zdrží, proto čti zadání pozorně a snaž se jim vyhnout.</li>
				<li className="mb-2">
					Místo poslední otázky se ti zobrazí magický link. Tedy, pokud to stihneš včas. Konkurence bude
					tvrdá.
				</li>
			</ul>
			<p className="mb-4 text-center">Zlom vaz!</p>
			<p className="text-center">
				<button className="btn btn--lg" onClick={onClick}>
					Jsem ready!
				</button>
			</p>
		</div>
	</>
);
