import { ChangeEvent, FC } from 'react';

type Props = {
	answer: string;
	type: string;
	handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
	selectedAnswers: string[];
};

export const Answer: FC<Props> = ({ answer, type, handleChange, selectedAnswers }) => {
	return (
		<div className="max-w-xl block mb-2">
			<label className={`inp-item inp-item--${type === 'hidden' ? 'option' : type}`}>
				<input
					type={type}
					value={answer}
					onChange={handleChange}
					checked={selectedAnswers.includes(answer)}
					className="inp-item__inp"
				></input>
				<span className="inp-item__text">{answer}</span>
				{type === 'hidden' ? <span className="inp"></span> : ''}
			</label>
		</div>
	);
};
