export const QUESTIONS = [
	{
		text: 'Co je to HTML?',
		type: 'checkbox',
		answers: [
			'HyperTwerking Mother-in-Law',
			'HyperText Makeup Language',
			'HyperText Markup Language',
			'Hyperspace Transport Magic Laundromat',
			'How To Make Love',
		],
		correct: ['HyperText Markup Language'],
	},
	{
		text: 'Co znamená zkratka AMP?',
		type: 'radio',
		answers: ["Ain't my problem", 'Accelerated Mobile Pages', 'Addict Mobile Phone', 'Asian Massage Police'],
		correct: ['Accelerated Mobile Pages'],
	},
	{
		text: 'Kdo vynalezl Internetový protokol?',
		type: 'radio',
		answers: ['CERN', 'ARPANET', 'Vint Cerf a Bob Kahn', 'Věra Pohlová'],
		correct: ['Vint Cerf a Bob Kahn'],
	},
	{
		text: 'Co NENÍ HTML5 element?',
		type: 'checkbox',
		answers: ['section', 'header', 'strike', 'mark'],
		correct: ['strike'],
	},
	{
		text: 'Které z následujících JSOU datové typy v JavaScriptu (vyber VŠECHNY)',
		type: 'checkbox',
		answers: ['boolean', 'double', 'undefined', 'string'],
		correct: ['boolean', 'undefined', 'string'],
	},
	{
		text: 'Co je to WAI-ARIA?',
		type: 'radio',
		answers: ['Webový standard', 'Web Access Internationaly', 'World AI - shARIA', 'Web Acessibility Initiative'],
		correct: ['Web Acessibility Initiative'],
	},
	{
		text: 'Který z uvedených je CSS framework?',
		type: 'radio',
		answers: ['Backbone', 'CSS Block', 'Bootstrap', 'Angular'],
		correct: ['Bootstrap'],
	},
	{
		text: 'Umíš používat Firebug / Dev Tools / Inspektor webu?',
		type: 'hidden',
		answers: ['Ano', 'Ne'],
		correct: ['Ano'],
	},
	{
		text: 'Co je CORS?',
		type: 'radio',
		answers: [
			'Cross-Origin Resource Sharing',
			'Covid Respiratory Syndrome',
			'Cross-origin Resource Scripting',
			'Crossfit-Origin Rapid System',
		],
		correct: ['Cross-Origin Resource Sharing'],
	},
	{
		text: 'Co vrátí: <code>return typeof []</code>?',
		type: 'radio',
		answers: ["'object'", "'array'", 'undefined', "'undefined'"],
		correct: ["'object'"],
	},
	{
		text: 'Kdo začal s vývojem Reactu?',
		type: 'radio',
		answers: ['Facebook', 'Google', 'Microsoft', 'SUPERKODERS'],
		correct: ['Facebook'],
	},
	{
		text: "Jaký je výstup <code>console.log(1 + '2' + '2');</code>?",
		type: 'radio',
		answers: ['undefined', '5', '122', 'SUPERKODERS'],
		correct: ['122'],
	},
	{
		text: 'Čeho je zkratka SVG?',
		type: 'radio',
		answers: [
			'Simply Vegan Glutenfree',
			'Scalable Vector Graphic',
			'Sampled Vector Graphic',
			'Symphonic Vogon Graphic',
		],
		correct: ['Scalable Vector Graphic'],
	},
	{
		text: 'Existují lepší kodéři než Superkoders?',
		type: 'radio',
		answers: ['Ne', 'Nikdo', 'Není možno', 'Chuck Norris'],
		correct: ['Ne', 'Nikdo', 'Není možno', 'Chuck Norris'],
	},
	{
		text: 'Máš funkční webkameru? Pokud se dostaneš do live přenosu, bude to potřeba!',
		type: 'radio',
		answers: ['Ano', 'Ne'],
		correct: ['Ano'],
	},
];
