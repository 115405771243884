import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { QUESTIONS } from '../questions';
import { Answer } from './Answer';
import { Overlay } from './Overlay';

type Props = {
	onAnswerAll: () => void;
};

export const QuestionPage: FC<Props> = ({ onAnswerAll }) => {
	const [question, setQuestion] = useState(0);
	const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
	const [disabledsSecs, setDisabledSecs] = useState(0);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selected = event.target.value;
		if (QUESTIONS[question].type === 'radio') {
			setSelectedAnswers([selected]);
		} else {
			if (selectedAnswers.includes(selected)) {
				setSelectedAnswers(selectedAnswers.filter((answer) => answer != selected));
			} else {
				setSelectedAnswers([...selectedAnswers, selected]);
			}
		}
	};
	const countDown = useCallback((timeout: number) => {
		setDisabledSecs(timeout);
		if (timeout > 0) {
			setTimeout(() => {
				countDown(timeout - 1);
			}, 1000);
		}
	}, []);

	const evaluateAnswer = () => {
		if (!selectedAnswers.length) {
			return;
		}
		if (
			(QUESTIONS[question].type === 'radio' || QUESTIONS[question].correct.length === selectedAnswers.length) &&
			selectedAnswers.every((answer) => QUESTIONS[question].correct.includes(answer))
		) {
			if (question + 1 < QUESTIONS.length) {
				setQuestion(question + 1);
			} else {
				onAnswerAll();
			}
		} else {
			countDown(10);
		}
		setSelectedAnswers([]);
	};
	return (
		<div className="self-center md_mx-auto md_w-152 max-w-full">
			<h1>
				<span className="text-gray-36 font-bold text-sm mb-1 block">
					Otázka č. {question + 1} z {QUESTIONS.length}
				</span>
				<span
					className="mb-6 text-md font-bold block"
					dangerouslySetInnerHTML={{ __html: QUESTIONS[question].text }}
				/>
			</h1>
			<div className="answers mb-6">
				{QUESTIONS[question].answers.map((answer) => (
					<Answer
						answer={answer}
						type={QUESTIONS[question].type}
						handleChange={handleChange}
						selectedAnswers={selectedAnswers}
						key={answer}
					/>
				))}
			</div>
			<button onClick={evaluateAnswer} disabled={!selectedAnswers.length && !disabledsSecs}>
				Vyhodnotit
			</button>
			{disabledsSecs > 0 && <Overlay disabledsSecs={disabledsSecs} />}
		</div>
	);
};
