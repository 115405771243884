import { FC, useEffect } from 'react';

type Props = {};
const LIVE_URL = 'http://frontendisti-soutez-2020.superkoderi.cz';

export const FinalPage: FC<Props> = () => {
	useEffect(() => {
		if (window) {
			setTimeout(() => {
				window.location.href = LIVE_URL;
			}, 3000);
		}
	}, []);
	return (
		<div className="self-center text-center">
			<h1 className="h1 mb-12">Super!</h1>
			<p className="h2 mb-12">Máš vše správně.</p>
			<p className="mb-12">
				Za několik vteřin tě automaticky přepojíme do živého vysílání. Nebo klikni na následující tlačítko:
			</p>
			<p className="text-center">
				<a className="btn btn--lg" href={LIVE_URL}>
					Připojit do studia
				</a>
			</p>
			<div className="flex justify-center mt-12">
				<img src="https://media.giphy.com/media/2A75RyXVzzSI2bx4Gj/giphy.gif" alt="" />
			</div>
		</div>
	);
};
